.App {
  text-align: center;
  background: #9fe8f6;
  height: 250vh;
}

.countdown {
  font-size: 40px;
  padding: 20px 0px;  
}

.timer {
  padding: 5vw 0;
}

.imageSalma {
  width: 150px;
  border-radius: 10px;
}

.birthdayMessage {
  background-color: #ffffff;
  border-radius: 7px;
  margin: 5vw 6vw;
  padding: 2vw 2vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
